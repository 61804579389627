"use client";

import { useEffect, useState } from "react";
import { getCode } from "./CodeBlock/getCode";

export default function CodeBlock({
  code,
  language,
  meta,
  lineNumbers = true,
  className,
  wrap = true,
}) {
  const placeholder = (
    <pre className={lineNumbers ? "pl-[4ch]" : ""}>{code}</pre>
  );
  const [codeBlock, setCodeBlock] = useState(placeholder);

  useEffect(() => {
    async function updateCodeBlock() {
      setCodeBlock(await getCode({ code, language, meta, lineNumbers, wrap }));
    }
    updateCodeBlock();
  }, [code, language, meta, lineNumbers, wrap]);
  return <div className={`bg-black p-4 ${className}`}>{codeBlock}</div>;
}
