import Icon from "@/components/Icon";

export default function BenefitBox({ benefit, color = "purple" }) {
  // Force include
  // bg-brand-purple/5
  // bg-brand-yellow/5
  // bg-brand-green/5
  // bg-brand-orange/5
  // bg-brand-blue/5
  // bg-brand-pink/5
  // bg-brand-magenta/5
  return (
    <div className={`bg-brand-${color}/5 rounded-lg overflow-hidden`}>
      <div className="p-4 xl:p-8 space-y-1 xl:space-y-2 text-center flex flex-col h-full items-center">
        <div className="flex flex-row sm:flex-col items-center justify-center gap-3 sm:gap-2">
          <Icon
            icon={benefit.icon}
            className={`w-8 h-8 md:w-10 md:h-10 2xl:w-12 2xl:h-12 text-brand-${color}`}
          />
          <h3 className="text-xl xl:text-2xl 2xl:text-3xl font-bold font-title">
            {benefit.title}
          </h3>
        </div>
        <p className="text-sm md:text-base xl:text-lg text-2">
          {benefit.description}
        </p>
      </div>
    </div>
  );
}
